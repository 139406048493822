import React from 'react'
import classes from './Footer.module.css';
import { ReactComponent as IconInstagram } from '../../assets/instagram.svg'
import { ReactComponent as IconWhatsapp } from '../../assets/whatsapp.svg'
import { whatsappNumber } from '../../data';

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <p><a id="link-instagram" href='https://www.instagram.com/lastore.franca' target='_blank' rel='noreferrer'><IconInstagram />lastore.franca</a></p>
      <p><a id="link-whatsapp" href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(`Gostaria de tirar algumas duvidas`)}`} target='_blank' rel='noreferrer'><IconWhatsapp/>Whatsapp</a></p>
    </footer>
  )
}

export default Footer