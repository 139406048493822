import React, { useContext } from "react";
import Modal from "../../utilities/Modal";
import WrapperButton from "../../utilities/WrapperButton";
import ButtonClose from "../../utilities/ButtonClose";
import classes from "./PurchaseSuccess.module.css";
import { ColorModeContext } from "../../../context/ColorModeContext";
import { CartContext } from "../../../context/CartContext";
import { whatsappNumber } from "../../../data";
import { ReactComponent as IconWhatsapp } from '../../../assets/whatsapp.svg'
import Crossfire from "react-canvas-confetti/dist/presets/crossfire";

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 10000,
}

const PurchaseSuccess = ({ onClose }) => {
  const { isDarkMode } = useContext(ColorModeContext)
  const { itemsCart, removeAll } = useContext(CartContext);

  function WhatsAppLink({ items }) {
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      `Gostaria de encomendar e ter mais informações sobre:\n\n` +
      items
        .map(
          (product) =>
            `${product.name} Ref ${product.refID} - Número ${product.variables} - Quantidade: ${product.amount}`
        )
        .join('\n\n')
    )}`;
    window.open(whatsappLink, '_blank', 'noopener,noreferrer');
  }

  const showFinalModalHandler = () => {
    onClose();
    removeAll();
    WhatsAppLink(itemsCart)
  };

  return (
    <>
      <Crossfire autorun={{ speed: 1, duration: 2000, delay: 500 }} style={canvasStyles} />
      <Modal onClose={onClose} isDarkMode={isDarkMode}>
        <div className={classes.modal}>
          <p className={classes.title}>Próximo passo!</p>
          <p className={classes.paragrafe}>Envie a mensagem gerada com as informações do seu pedido pelo WhatsApp.</p>
          <p className={classes.paragrafe}>Após o pagamento confirmado, seu pedido será preparado imediatamente.</p>
          <WrapperButton className={classes.button} onClick={showFinalModalHandler}>
            Enviar Pedido<span><IconWhatsapp /></span>
          </WrapperButton>
        </div>
        <ButtonClose onClose={onClose} />
      </Modal>
    </>
  );
};

export default PurchaseSuccess;
