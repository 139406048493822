export const whatsappNumber = 5516992102349;
export const rate = 5;
const refPremium = "124.99";
const priceRef00 = "84.99";
const priceRef01 = "84.99";
const priceRef02 = "99.99";
const priceRef03 = "99.99";
const priceRef04 = "99.99";
const priceRef05 = "109.99";
const priceRef06 = "109.99";
const priceRef07 = "114.99";
const priceRef08 = "114.99";
const priceRef09 = "124.99";
const priceRef010 = "124.99";
const priceRef011 = "129.88";
// const priceRef012 = "139.99";
const priceRef013 = "149.99";
// const priceRef014 = "199.99";
// const priceRef015 = "189.99";
// const priceRef016 = "209.99";
// const priceRef017 = "0";
// const priceRef018 = "209.99";

export const all_products = [
  {
    name: "Tênis Nike - Air Force Premium White",
    price: refPremium,
    installmentQtd: "3",
    refID: "003",
    img: "img2.webp",
    category: "Nike",
    color: "branco",
    flag: "Premium",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Full White",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img22.webp",
    category: "Nike",
    color: "branco",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Branco Vermelho",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img112.webp",
    category: "Nike",
    color: "vermelho",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Preto Bordado",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img113.webp",
    category: "Nike",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Marron",
    price: priceRef05,
    installmentQtd: "3",
    refID: "005",
    img: "img118.webp",
    category: "Nike",
    color: "marron",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Preto",
    price: priceRef05,
    installmentQtd: "3",
    refID: "005",
    img: "img106.webp",
    category: "Nike",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Premium Bordô Ouro",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img129.webp",
    category: "Nike",
    color: "bordo",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Black e Red",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img12.webp",
    category: "Nike",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Caramelo Camurça",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img13.webp",
    category: "Nike",
    color: "marron",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Branco Caramelo",
    flag:"Premium",
    price: priceRef09,
    installmentQtd: "3",
    refID: "009",
    img: "img27.webp",
    category: "Nike",
    color: "marron",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Bege Dourado",
    price: priceRef02,
    installmentQtd: "3",
    refID: "002",
    img: "img28.webp",
    category: "Nike",
    color: "bege",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Bege Azul",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img32.webp",
    category: "Nike",
    color: "bege",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Branco Verde Oliva",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img33.webp",
    category: "Nike",
    color: "verde",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Branco Azul com Prata",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img29.webp",
    category: "Nike",
    color: "azul",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Branco Azul Claro com Prata",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img35.webp",
    category: "Nike",
    color: "azul",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Branco Cinza com Prata",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img34.webp",
    category: "Nike",
    color: "cinza",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Branco Cinza",
    price: priceRef05,
    installmentQtd: "3",
    refID: "005",
    img: "img39.webp",
    category: "Nike",
    color: "cinza",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Branco Nude",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img36.webp",
    category: "Nike",
    color: "bege",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Branco com Azul",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img30.webp",
    category: "Nike",
    color: "azul",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Cinza",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img18.webp",
    category: "Nike",
    color: "cinza",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Off White Preto",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img37.webp",
    category: "Nike",
    color: "offwhite",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Branco Verde",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img38.webp",
    category: "Nike",
    color: "verde",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Preto Verde",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img67.webp",
    category: "Nike",
    color: "verde",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Nobuck Preto",
    price: priceRef05,
    installmentQtd: "3",
    refID: "005",
    img: "img26.webp",
    category: "Nike",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Full Black",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img19.webp",
    category: "Nike",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Lilás Amarelo",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img58.webp",
    category: "Nike",
    color: "colorido",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Moo Colorido",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img66.webp",
    category: "Nike",
    color: "colorido",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force NBA Lakers",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img40.webp",
    category: "Nike",
    color: "branco",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Force Full White Infantil",
    price: priceRef00,
    installmentQtd: "3",
    refID: "000",
    img: "img146.webp",
    category: "Nike",
    color: "branco",
    variables:['26','27','28','29','30','31','32','33','34'],
    ageRange: "Infantil",
  },
  {
    name: "Tênis Nike - Air Force Divertidamente Infantil",
    price: priceRef00,
    installmentQtd: "3",
    refID: "000",
    img: "img143.webp",
    category: "Nike",
    color: "roxo",
    variables:['26','27','28','29','30','31','32','33','34'],
    ageRange: "Infantil",
  },
  {
    name: "Tênis Nike - Air Jordan Chicago Azul",
    price: priceRef07,
    installmentQtd: "3",
    refID: "007",
    img: "img90.webp",
    category: "Nike",
    color: "azul",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Jordan Chicago Bege Preto Branco",
    price: priceRef07,
    installmentQtd: "3",
    refID: "007",
    img: "img123.webp",
    category: "Nike",
    color: "bege",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Jordan Aveia",
    price: priceRef07,
    installmentQtd: "3",
    refID: "007",
    img: "img48.webp",
    category: "Nike",
    color: "marron",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Jordan Branco Azul",
    price: priceRef07,
    installmentQtd: "3",
    refID: "007",
    img: "img49.webp",
    category: "Nike",
    color: "branco",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Jordan Branco Jeans",
    price: priceRef07,
    installmentQtd: "3",
    refID: "007",
    img: "img50.webp",
    category: "Nike",
    color: "azul",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Jordan Cinza Amarelo",
    price: priceRef07,
    installmentQtd: "3",
    refID: "007",
    img: "img51.webp",
    category: "Nike",
    color: "cinza",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Dunk White Black",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img52.webp",
    category: "Nike",
    color: "branco",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Dunk Preto Branco",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img104.webp",
    category: "Nike",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Dunk Cinza Preto",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img55.webp",
    category: "Nike",
    color: "cinza",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Dunk Azul Rosa",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img54.webp",
    category: "Nike",
    color: "rosa",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Dunk Bege Azul Low",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img119.webp",
    category: "Nike",
    color: "bege",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Dunk Marron Café",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img56.webp",
    category: "Nike",
    color: "marron",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Dunk Marron",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img64.webp",
    category: "Nike",
    color: "marron",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Dunk Cinza Branco",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img57.webp",
    category: "Nike",
    color: "cinza",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Dunk Cinza Rosa",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img63.webp",
    category: "Nike",
    color: "rosa",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Dunk Branco Verde Laranja",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img60.webp",
    category: "Nike",
    color: "laranja",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Dunk Bege Verde Salmão",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img61.webp",
    category: "Nike",
    color: "laranja",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Dunk Branco Verde Limão",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img59.webp",
    category: "Nike",
    color: "verde claro",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Dunk Black",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img62.webp",
    category: "Nike",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Dunk Authentic",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img53.webp",
    category: "Nike",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Dunk Preto Branco Infantil",
    price: priceRef00,
    installmentQtd: "3",
    refID: "000",
    img: "img137.webp",
    category: "Nike",
    color: "preto",
    variables:['26','27','28','29','30','31','32','33','34'],
    ageRange: "Infantil",
  },
  {
    name: "Tênis Nike - Dunk Black Infantil",
    price: priceRef01,
    installmentQtd: "3",
    refID: "001",
    img: "img136.webp",
    category: "Nike",
    color: "preto",
    variables:['26','27','28','29','30','31','32','33','34'],
    ageRange: "Infantil",
  },
  {
    name: "Tênis Nike - Dunk Colorido Infantil",
    price: priceRef00,
    installmentQtd: "3",
    refID: "000",
    img: "img142.webp",
    category: "Nike",
    color: "colorido",
    variables:['26','27','28','29','30','31','32','33','34'],
    ageRange: "Infantil",
  },
  {
    name: "Tênis Nike - Dunk Rosa Barbie Infantil",
    price: priceRef00,
    installmentQtd: "3",
    refID: "000",
    img: "img138.webp",
    category: "Nike",
    color: "rosa",
    variables:['26','27','28','29','30','31','32','33','34'],
    ageRange: "Infantil",
  },
  {
    name: "Tênis Nike - Air Max TN Preto",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img109.webp",
    category: "Nike",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Max TN Branco",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img110.webp",
    category: "Nike",
    color: "branco",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Max Branco Cinza Preto",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img121.webp",
    category: "Nike",
    color: "cinza",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Max Branco",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img87.webp",
    category: "Nike",
    color: "branco",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Max Bege Preto",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img86.webp",
    category: "Nike",
    color: "bege",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Epic Cinza/Rosa",
    price: priceRef08,
    installmentQtd: "3",
    refID: "008",
    img: "img11.webp",
    category: "Nike",
    color: "rosa",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Epic Preto/Rosa",
    price: priceRef08,
    installmentQtd: "3",
    refID: "008",
    img: "img14.webp",
    category: "Nike",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Running Rosa Cinza",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img120.webp",
    category: "Nike",
    color: "cinza",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Zoom Pegasus Rosa",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img24.webp",
    category: "Nike",
    color: "rosa",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Zoom Pegasus Preto Rosa Degrade",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img25.webp",
    category: "Nike",
    color: "rosa",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Zoom Preto Branco",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img47.webp",
    category: "Nike",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Zoom Azul Branco",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img102.webp",
    category: "Nike",
    color: "azul",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Zoom Preto Laranja",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img46.webp",
    category: "Nike",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Max 270 Colorido",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img92.webp",
    category: "Nike",
    color: "colorido",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Max 270 Preto Cinza Branco",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img93.webp",
    category: "Nike",
    color: "cinza",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Max 270 Preto Cinza",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img128.webp",
    category: "Nike",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Max 270 Azul",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img127.webp",
    category: "Nike",
    color: "azul",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Max 270 Colorido V2",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img94.webp",
    category: "Nike",
    color: "colorido",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Air Max 270 Preto Cinza Infantil",
    price: priceRef00,
    installmentQtd: "3",
    refID: "000",
    img: "img144.webp",
    category: "Nike",
    color: "preto",
    variables:['26','27','28','29','30','31','32','33','34'],
    ageRange: "Infantil",
  },
  {
    name: "Tênis Nike - Air Max 270 Cinza Branco Infantil",
    price: priceRef00,
    installmentQtd: "3",
    refID: "003",
    img: "img145.webp",
    category: "Nike",
    color: "cinza",
    variables:['26','27','28','29','30','31','32','33','34'],
    ageRange: "Infantil",
  },
  {
    name: "Tênis Nike - React Infinity Preto",
    price: priceRef010,
    installmentQtd: "3",
    refID: "010",
    img: "img115.webp",
    category: "Nike",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - React Infinity Cinza",
    price: priceRef010,
    installmentQtd: "3",
    refID: "010",
    img: "img124.webp",
    category: "Nike",
    color: "cinza",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - React Infinity Verde Água",
    price: priceRef010,
    installmentQtd: "3",
    refID: "010",
    img: "img41.webp",
    category: "Nike",
    color: "branco",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - React Infinity Azul Amarelo",
    price: priceRef010,
    installmentQtd: "3",
    refID: "010",
    img: "img114.webp",
    category: "Nike",
    color: "azul",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - VaporFly Rosa",
    price: priceRef09,
    installmentQtd: "3",
    refID: "009",
    img: "img42.webp",
    category: "Nike",
    color: "rosa",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - VaporFly Branco Rosa",
    price: priceRef09,
    installmentQtd: "3",
    refID: "009",
    img: "img45.webp",
    category: "Nike",
    color: "rosa",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - VaporFly Verde Água",
    price: priceRef09,
    installmentQtd: "3",
    refID: "009",
    img: "img43.webp",
    category: "Nike",
    color: "verde",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - VaporFly Preto Rosa",
    price: priceRef09,
    installmentQtd: "3",
    refID: "009",
    img: "img117.webp",
    category: "Nike",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - VaporFly Preto Azul",
    price: priceRef09,
    installmentQtd: "3",
    refID: "009",
    img: "img44.webp",
    category: "Nike",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Nike - Meia Preto Branco",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img68.webp",
    category: "Nike",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Louis Vuitton - Trainer Azul",
    price: priceRef011,
    installmentQtd: "3",
    refID: "011",
    img: "img100.webp",
    category: "Louis Vuitton",
    color: "azul",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Louis Vuitton - Trainer Bege",
    price: priceRef011,
    installmentQtd: "3",
    refID: "011",
    img: "img101.webp",
    category: "Louis Vuitton",
    color: "bege",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Louis Vuitton - Trainer Branco Cinza",
    price: priceRef011,
    installmentQtd: "3",
    refID: "011",
    img: "img108.webp",
    category: "Louis Vuitton",
    color: "cinza",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Louis Vuitton - Trainer Preto",
    price: priceRef011,
    installmentQtd: "3",
    refID: "011",
    img: "img111.webp",
    category: "Louis Vuitton",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Louis Vuitton - Skate Verde",
    price: priceRef013,
    installmentQtd: "3",
    refID: "013",
    img: "img3.webp",
    category: "Louis Vuitton",
    color: "verde",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Louis Vuitton - Skate Preto",
    price: priceRef013,
    installmentQtd: "3",
    refID: "013",
    img: "img4.webp",
    category: "Louis Vuitton",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Louis Vuitton - Skate Vermelho",
    price: priceRef013,
    installmentQtd: "3",
    refID: "013",
    img: "img5.webp",
    category: "Louis Vuitton",
    color: "vermelho",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Versat - Roav Branco",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img126.webp",
    category: "New Balance",
    color: "branco",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Versat - V Preto Branco",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img77.webp",
    category: "Versat",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Versat - V Branco com Dourado",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img6.webp",
    category: "Versat",
    color: "dourado",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Versat - V Rose",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img7.webp",
    category: "Versat",
    color: "rose",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Versat - V Dourado",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img8.webp",
    category: "Versat",
    color: "dourado",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Vans - KNU Skool Black White",
    price: priceRef08,
    installmentQtd: "3",
    refID: "008",
    img: "img10.webp",
    category: "Vans",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Vans - KNU Café/Rosa",
    price: priceRef08,
    installmentQtd: "3",
    refID: "008",
    img: "img9.webp",
    category: "Vans",
    color: "marron",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Vans - KNU Bege",
    price: priceRef08,
    installmentQtd: "3",
    refID: "008",
    img: "img71.webp",
    category: "Vans",
    color: "bege",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Vans - Neo Black White",
    price: priceRef09,
    installmentQtd: "3",
    refID: "009",
    img: "img69.webp",
    category: "Vans",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  }, 
  {
    name: "Tênis Vans - Neo Bege",
    price: priceRef09,
    installmentQtd: "3",
    refID: "009",
    img: "img105.webp",
    category: "Vans",
    color: "bege",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  }, 
  {
    name: "Tênis Vans - Neo Ultrange",
    price: priceRef09,
    installmentQtd: "3",
    refID: "009",
    img: "img70.webp",
    category: "Vans",
    color: "branco",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Vans - Neo Ultrange VR3 Black Marshmallow",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img72.webp",
    category: "Vans",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Vans - Neo Ultrange VR3 Verde Musgo",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img73.webp",
    category: "Vans",
    color: "verde",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Vans - Neo Ultrange VR3 Cinza Marshmallow",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img74.webp",
    category: "Vans",
    color: "cinza",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Vans - Neo Ultrange Black",
    price: priceRef03,
    installmentQtd: "3",
    refID: "003",
    img: "img75.webp",
    category: "Vans",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Vans - Old Skool Black White",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img76.webp",
    category: "Vans",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Vans - Old Skool Black White Infantil",
    price: priceRef00,
    installmentQtd: "3",
    refID: "000",
    img: "img132.webp",
    category: "Vans",
    color: "preto",
    variables:['26','27','28','29','30','31','32','33','34'],
    ageRange: "Infantil",
  },
  {
    name: "Tênis All Star - Cano Alto Preto",
    price: priceRef02,
    installmentQtd: "3",
    refID: "002",
    img: "img95.webp",
    category: "All Star",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis All Star - Courino Branco",
    price: priceRef02,
    installmentQtd: "3",
    refID: "002",
    img: "img96.webp",
    category: "All Star",
    color: "branco",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis All Star - Off White",
    price: priceRef02,
    installmentQtd: "3",
    refID: "002",
    img: "img97.webp",
    category: "All Star",
    color: "offwhite",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis All Star - Courino Preto",
    price: priceRef02,
    installmentQtd: "3",
    refID: "002",
    img: "img98.webp",
    category: "All Star",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis All Star - Vermelho",
    price: priceRef02,
    installmentQtd: "3",
    refID: "002",
    img: "img99.webp",
    category: "All Star",
    color: "vermelho",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Adidas - Campus Verde",
    price: priceRef010,
    installmentQtd: "3",
    refID: "010",
    img: "img78.webp",
    category: "Adidas",
    color: "verde",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },  
  {
    name: "Tênis Adidas - Campus Bege",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img125.webp",
    category: "Adidas",
    color: "bege",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Adidas - Campus Black",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img23.webp",
    category: "Adidas",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },  
  {
    name: "Tênis Adidas - Campus Cinza",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img79.webp",
    category: "Adidas",
    color: "cinza",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Adidas - Campus Cinza Preto",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img84.webp",
    category: "Adidas",
    color: "cinza",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Adidas - Campus Azul Ceu",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img80.webp",
    category: "Adidas",
    color: "azul",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },  
  {
    name: "Tênis Adidas - Campus Cinza Verde",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img83.webp",
    category: "Adidas",
    color: "verde",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Adidas - Campus Marron",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img82.webp",
    category: "Adidas",
    color: "marron",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },  
  {
    name: "Tênis Adidas - Campus Black Infantil",
    price: priceRef00,
    installmentQtd: "3",
    refID: "000",
    img: "img133.webp",
    category: "Adidas",
    color: "preto",
    variables:['26','27','28','29','30','31','32','33','34'],
    ageRange: "Infantil",
  },  
  {
    name: "Tênis Adidas - Campus Azul Ceu Infantil",
    price: priceRef00,
    installmentQtd: "3",
    refID: "000",
    img: "img134.webp",
    category: "Adidas",
    color: "azul",
    variables:['26','27','28','29','30','31','32','33','34'],
    ageRange: "Infantil",
  },  
  {
    name: "Tênis Adidas - Campus Rosa Infantil",
    price: priceRef00,
    installmentQtd: "3",
    refID: "000",
    img: "img135.webp",
    category: "Adidas",
    color: "rosa",
    variables:['26','27','28','29','30','31','32','33','34'],
    ageRange: "Infantil",
  },  
  {
    name: "Tênis Adidas - Samba Black",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img15.webp",
    category: "Adidas",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },  
  {
    name: "Tênis Adidas - Samba White",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img16.webp",
    category: "Adidas",
    color: "branco",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },  
  {
    name: "Tênis Adidas - Samba Branco Azul",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img17.webp",
    category: "Adidas",
    color: "azul",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },  
  {
    name: "Tênis Adidas - Super Star Branco Preto",
    price: priceRef02,
    installmentQtd: "3",
    refID: "002",
    img: "img91.webp",
    category: "Adidas",
    color: "branco",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },  
  {
    name: "Tênis Adidas - Ixson Rosa",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img130.webp",
    category: "Adidas",
    color: "rosa",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },  
  {
    name: "Tênis Adidas - Ixson Roxo",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img131.webp",
    category: "Adidas",
    color: "roxo",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },  
  {
    name: "Tênis Adidas - Slip On Preto e Branco",
    price: priceRef02,
    installmentQtd: "3",
    refID: "002",
    img: "img122.webp",
    category: "Adidas",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },  
  {
    name: "Tênis Adidas - LXCom Preto",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img85.webp",
    category: "Adidas",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },  
  {
    name: "Tênis Mizuno - Nirnava Azul",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img20.webp",
    category: "Mizuno",
    color: "azul",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },  
  {
    name: "Tênis Mizuno - Nirnava Cinza",
    price: priceRef04,
    installmentQtd: "3",
    refID: "004",
    img: "img21.webp",
    category: "Mizuno",
    color: "cinza",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Mizuno - Wave Cinza Laranja",
    price: priceRef07,
    installmentQtd: "3",
    refID: "007",
    img: "img116.webp",
    category: "Mizuno",
    color: "cinza",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Mizuno - Wave Azul",
    price: priceRef07,
    installmentQtd: "3",
    refID: "007",
    img: "img107.webp",
    category: "Mizuno",
    color: "azul",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Olympikus - Lev's Preto Branco Infantil",
    price: priceRef01,
    installmentQtd: "3",
    refID: "001",
    img: "img141.webp",
    category: "Olympikus",
    color: "preto",
    variables:['26','27','28','29','30','31','32','33','34'],
    ageRange: "Infantil",
  },
  {
    name: "Tênis Olympikus - Lev's Rosa Infantil",
    price: priceRef01,
    installmentQtd: "3",
    refID: "001",
    img: "img139.webp",
    category: "Olympikus",
    color: "rosa",
    variables:['26','27','28','29','30','31','32','33','34'],
    ageRange: "Infantil",
  },
  {
    name: "Tênis Olympikus - Lev's Laranja Infantil",
    price: priceRef01,
    installmentQtd: "3",
    refID: "001",
    img: "img140.webp",
    category: "Olympikus",
    color: "laranja",
    variables:['26','27','28','29','30','31','32','33','34'],
    ageRange: "Infantil",
  },
  {
    name: "Tênis Oldsen - Unissex Branco Preto",
    price: priceRef08,
    installmentQtd: "3",
    refID: "008",
    img: "img88.webp",
    category: "Oldsen",
    color: "branco",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
  {
    name: "Tênis Reebok - Unissex Preto",
    price: priceRef06,
    installmentQtd: "3",
    refID: "006",
    img: "img31.webp",
    category: "Reebok",
    color: "preto",
    variables:['34','35','36','37','38','39','40','41','42', '43'],
    ageRange: "Adulto",
  },
];

export const colors = [
  {
    colorName: "rosa",
    bg: "#FE96C2",
    hex: "#570B2B",
  },
  {
    colorName: "verde",
    bg: "#7FD878",
    hex: "#245320",
  },
  {
    colorName: "verde claro",
    bg: "#a1f10b",
    hex: "#245320",
  },
  {
    colorName: "laranja",
    bg: "#F4B770",
    hex: "#563309",
  },
  {
    colorName: "marron",
    bg: "#ac8872",
    hex: "#5C2E2E",
  },
  {
    colorName: "bege", 
    bg: "#F5F5DC",
    hex: "#D2B48C",
  },
  {
    colorName: "offwhite", 
    bg: "#FAF9F6",
    hex: "#EAEAEA",
  },
  {
    colorName: "azul",
    bg: "#96C0FE",
    hex: "#1F3556",
  },
  {
    colorName: "vermelho",
    bg: "#F56F6B",
    hex: "#520F0F",
  },
  {
    colorName: "bordo",
    bg: "#800020",
    hex: "#4C0014",
  },
  {
    colorName: "roxo",
    bg: "#e0d1ff",
    hex: "#2A1B4A",
  },
  {
    colorName: "amarelo",
    bg: "#F1F371",
    hex: "#4A4B0A",
  },
  {
    colorName: "dourado", 
    bg: "#ecdda3", 
    hex: "#B8860B", 
  },
  {
    colorName: "rose",
    bg: "#FFC0CB",
    hex: "#FF007F",
  },
  {
    colorName: "preto",
    bg: "#5C5C5C",
    color: "#FFFFFF",
  },
  {
    colorName: "cinza", 
    bg: "#D3D3D3", 
    hex: "#A9A9A9",
  },
  {
    colorName: "branco",
    bg: "#FFFFFF",
    color: "#2C2C2C",
  },
  {
    colorName: "colorido",
    bg: "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,255,0,1) 20%, rgba(0,255,196,1) 50%, rgba(109,0,255,1) 80%, rgba(255,0,0,1) 100%)",
    color: "#2C2C2C",
  },
];