import React, { useContext, useEffect, useState } from "react";
import WrapperFiltroSection from "../utilities/WrapperFiltroSection";
import { FilterContext } from "../../context/FilterContext";
import classes from "./FormAgeRange.module.css";

// const ageRange = ["Adulto", "Infantil"];

const FormAgeRange = ({ setFilter, filterVal }) => {
  const [selectedAgeRange, setSelectedAgeRange] = useState(filterVal);
  const { filteredProducts } = useContext(FilterContext);
  const filterAgeRange = Array.from(new Set(filteredProducts.map(({ ageRange }) => ageRange)));

  const handleChange = ({ target }) => {
    if (target.checked) {
      setSelectedAgeRange([...selectedAgeRange, target.value]);
    } else {
      setSelectedAgeRange((currValue) => {
        return currValue.filter((val) => val !== target.value);
      });
    }
  };

  useEffect(() => {
    setFilter(selectedAgeRange);
  }, [selectedAgeRange, setFilter]);

  useEffect(() => {
    setSelectedAgeRange(filterVal);
  }, [filterVal]);

  return (
    <WrapperFiltroSection title="Faixa Etária">
      <form className={classes.form}>
        {/* {ageRange.map((ageRange) => ( */}
        {filterAgeRange.map((ageRange) => (
          <div className={classes.divInput} key={ageRange}>
            <input
              type="checkbox"
              name="categoria"
              id={ageRange}
              value={ageRange}
              onChange={handleChange}
              checked={selectedAgeRange?.includes(ageRange)}
            />
            <label htmlFor={ageRange}>{ageRange}</label>
          </div>
        ))}
      </form>
    </WrapperFiltroSection>
  );
};

export default FormAgeRange;
