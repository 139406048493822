import React, { useContext, useEffect, useState } from "react";
import WrapperFiltroSection from "../utilities/WrapperFiltroSection";
import { FilterContext } from "../../context/FilterContext";
import classes from "./FormPrice.module.css";

const FormPrice = ({ setFilter }) => {
  const { filteredProducts } = useContext(FilterContext);
  const uniquePrices = Array.from(new Set(filteredProducts.map(({ price }) => price)));
  const maxPrice = Math.max(...uniquePrices);
  const minPrice = Math.min(...uniquePrices);
  const [prices, setPrices] = useState({ min: minPrice, max: maxPrice });

  const handleChangeMin = ({ target }) => {
    setPrices((currVal) => ({ ...currVal, min: target.value }));
  };

  const handleChangeMax = ({ target }) => {
    setPrices((currVal) => ({ ...currVal, max: target.value }));
  };

  useEffect(() => {
    setFilter(prices);
  }, [setFilter, prices]);

  return (
    <WrapperFiltroSection title="Preço">
      <form className={classes.form}>
        <label htmlFor="min">
          Min (R$)
          <input
            type="number"
            step="0.01"
            min={prices.min}
            name="valor_min"
            id="min"
            value={prices.min}
            onChange={handleChangeMin}
          />
        </label>
        <label htmlFor="max">
          Max (R$)
          <input
            type="number"
            step="0.01"
            max={prices.max}
            name="valor_max"
            id="max"
            value={prices.max}
            onChange={handleChangeMax}
          />
        </label>
      </form>
    </WrapperFiltroSection>
  );
};

export default FormPrice;
