import React, { useContext, useEffect, useState } from "react";
import WrapperFiltroSection from "../utilities/WrapperFiltroSection";
import { FilterContext } from "../../context/FilterContext";
import { colors } from "../../data";
import classes from "./FormColors.module.css";

const FormCores = ({ setFilter, filterVal }) => {
  const [selectedColors, setSelectedColors] = useState([]);
  const { filteredProducts } = useContext(FilterContext);
  const uniqueColorNames = Array.from(new Set(filteredProducts?.map(({ color }) => color)));
  const filterColors = uniqueColorNames.map((colorName) => {
    const foundColor = colors.find(color => color.colorName === colorName);
    return foundColor || { colorName, message: "Color not found" };
  });
  const handleChange = ({ target }) => {
    if (target.checked) {
      setSelectedColors([...selectedColors, target.value]);
    } else {
      setSelectedColors((currValue) => {
        return currValue.filter((val) => val !== target.value);
      });
    }
  };

  useEffect(() => {
    setFilter(selectedColors);
  }, [setFilter, selectedColors]);

  useEffect(() => {
    setSelectedColors(filterVal);
  }, [filterVal]);

  return (
    <WrapperFiltroSection title="Cores">
      <form className={classes.listColors}>
        {filterColors.map((item) => (
          <div
            className={`${classes.colorItem} ${selectedColors.includes(item.colorName) ? classes.checked : ""
              }`}
            key={item.colorName}
          >
            <label
              htmlFor={item.colorName}
              style={{ background: item.bg, color: item.hex }}
              className={classes.btnColor}
              title={item.colorName}
            >
              <span>{item.colorName}</span>
            </label>
            <input
              type="checkbox"
              name="colors"
              id={item.colorName}
              value={item.colorName}
              onChange={handleChange}
              checked={selectedColors.includes(item.colorName)}
            />
          </div>
        ))}
      </form>
    </WrapperFiltroSection>
  );
};

export default FormCores;
