import React, { useState } from "react";
import classes from "./CartItem.module.css";
import { ReactComponent as LogoSvg } from "../../assets/logo.svg";
import { ReactComponent as IconPremium } from "../../assets/premium.svg";
import { rate } from "../../data";
import { useEffect } from "react";

const getProductImage = (img, name, flag) => {
  try {
    return (
      <>
        {flag && <span className={classes.flagImage}>{flag} <IconPremium /></span>}
        <img src={require(`../../assets/imgs-produtos/${img}`)} alt={name} width="112px" height="112px" />
      </>
    )
  } catch (error) {
    return <LogoSvg />
  }
};

const CarItem = ({ name, price, installmentQtd, variables, img, flag, amount, updateAmount, removeItem }) => {
  const [newAmount, setNewAmount] = useState(amount);

  useEffect(() => {
    if (newAmount !== amount) {
      updateAmount(name, newAmount, variables);
    }
  }, [newAmount, amount, name, variables, updateAmount]);

  const updateAmountHandler = (e) => {
    const value = +e.target.value;
    setNewAmount(value);
    updateAmount(name, value, variables);
  };

  const removeItemFromCart = () => {
    removeItem(name, variables);
  };
  // Funções para alterar a quantidade
  const incrementAmount = () => setNewAmount((prev) => prev + 1);
  const decrementAmount = () => setNewAmount((prev) => Math.max(prev - 1, 1));

  return (
    <li className={classes.item}>
      <div className={classes.imgDiv}>
        {getProductImage(img, name, flag)}
      </div>
      <div className={classes.infos}>
        <p className={classes.listVariable}>{name} {variables && (<span className={classes.itemVariable}>Nº{variables}</span>)}</p>
        <p>
          <span className={classes.amount}>{amount} unidade(s)</span> de R$ {Number(price).toFixed(2)}
        </p>
        {installmentQtd && <p className={classes.installmentQtd}>{Number(installmentQtd)} x de R$ {(((Number(price) + rate) / installmentQtd) * amount).toFixed(2)} no Cartão</p>}
        <p className={classes.price}>R$ {Number(price * amount).toFixed(2)}</p>
        <form className={classes.amountForm}>
          <label htmlFor="quantidade">Quantidade.</label>
          <button
            className={classes.btnAddRemove}
            onClick={(e) => { e.preventDefault(); decrementAmount(); }}
            title="adicionar produto"
          >
            -
          </button>
          <label htmlFor={`amount-${name}`}>
            <input
              type="number"
              name="quantidade"
              id={`amount-${name}`}
              value={newAmount}
              onChange={updateAmountHandler}
              step="1"
              min="1"
              readOnly
            />
          </label>
          <button
            className={classes.btnAddRemove}
            onClick={(e) => { e.preventDefault(); incrementAmount(); }}
            title="Remover carrinho"
          >
            +
          </button>
        </form>
        <button className={classes.btn} onClick={removeItemFromCart}>
          Excluir item
        </button>
      </div>
    </li>
  );
};

export default CarItem;
