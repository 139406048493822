import React, { useContext } from "react";
import Modal from "../utilities/Modal";
import classes from "./QuickView.module.css";
import ButtonClose from "../utilities/ButtonClose";
import { ColorModeContext } from "../../context/ColorModeContext";
import { ReactComponent as LogoSvg } from "../../assets/logo.svg";

const getProductImage = (img, name) => {
  try {
    return <img src={require(`../../assets/imgs-produtos/${img}`)} alt={name} />;
  } catch (error) {
    return <LogoSvg />
  }
};

const QuickView = ({ onClose, img, name }) => {

  const { isDarkMode } = useContext(ColorModeContext);

  return (
    <Modal onClose={onClose} isDarkMode={isDarkMode}>
      <div className={classes.imgDiv}>
        {getProductImage(img, name)}
      </div>
      <ButtonClose onClose={onClose} />
    </Modal>
  );
};

export default QuickView;
